<template>
  <div id="Lift">
    <moModel :moData="moData"></moModel>
  </div>
</template>

<script>
import moModel from "../../home/components/models";
import { get } from "@get/http";
import { getQueryVariable } from "@get/util";
export default {
  name: "ProductList",
  components: {
    moModel,
  },
  data: () => ({
    moData: [],
  }),

  created() {
    this.getIndex();
  },
  methods: {
    async getIndex() {
      let store_id = getQueryVariable("store_id");
      let id = getQueryVariable("s_id");
      let page_type = getQueryVariable("page_type");
      let { page_config } = await get("/index/mofang2", {
        params: {
          store_id,
          id,
          page_type,
        },
      });
      this.moData = page_config;
    },
  },
};
</script>

<style lang="scss"></style>
